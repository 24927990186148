@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "custom";



.leaflet-popup-content-wrapper {
  @extend .py-1;
  @extend .ps-1;
  @extend .pe-3;


  .leaflet-popup-content {
    margin: 0
  }
}

.leaflet-popup-tip-container {
}
.leaflet-bottom.leaflet-right{
  display: none;
}