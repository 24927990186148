


$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #888 !default;
$gray-700: #444 !default;
$gray-800: #303030 !default;
$gray-900: #222 !default;
$black:    #000 !default;

$blue:    #375a7f !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e74c3c !default;
$orange:  #fd7e14 !default;
$yellow:  #f39c12 !default;
$green:   #00bc8c !default;
$teal:    #20c997 !default;
$cyan:    #3498db !default;

$primary:         #13495b !default;
//$secondary:     $blue !default;
//$success:       $green !default;
$info:            #0dcaf0 !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-500 !default;
//$dark:          $gray-800 !default;

//$min-contrast-ratio:   1.9 !default;

// Container



// Body

$body-bg:                   $white !default;
//$body-color:                $black !default;

// Links

//$link-color:                $black !default;
//$link-decoration:           none !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:      "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
//$h1-font-size:                3rem !default;
//$h2-font-size:                2.5rem !default;
//$h3-font-size:                2rem !default;
//$text-muted:                  $gray-600 !default;


$input-bg:                              $gray-100;
$input-focus-bg:                        $white;


$input-btn-focus-box-shadow:   0 0 0 0.2rem transparent;
$input-focus-border-color:     $cyan;


$btn-focus-box-shadow:        0 0 0 0.2rem transparent;
